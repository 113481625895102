// import _this from "@/main";

/**
 * 本地创建一条推动消息
 */
export function createLocalPushMsg(title, msg, type) {
  let plus = window.plus;
  if (plus) {
    let options = { cover: false, title: title };
    plus.push.createMessage(msg, type, options);
  }
}
