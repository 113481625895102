<template>
  <div
    id="focus"
    :style="`height:${screenHeight}px;background-color: ${appThemeConfig.appBgColor}; padding-top: 0px`"
  >
    <app-system-bar
      v-if="isApp"
      style="position: fixed; opacity: 0.05"
    ></app-system-bar>

    <v-btn
      class="ml-1"
      :style="`top:${isApp ? systemBarHeight + 19 : 19}px`"
      absolute
      left
      fab
      small
      elevation="1"
      color="#ffffff50"
      @click="goIndex"
    >
      <v-icon color="#1976d2">mdi-arrow-left</v-icon>
    </v-btn>

    <v-row class="focus-content">
      <v-col class="col" cols="12" xs="12" md="4">
        <div class="timer" v-if="this.timer">
          <v-progress-circular
            style="width: 11rem; height: 11rem"
            :rotate="-90"
            size="85"
            :width="5"
            :value="progressValue"
            color="#1976d2"
            ><span style="min-width: 3rem">{{
              String(timer.m).padStart(2, "0")
            }}</span>
            <span style="color: #6d789d; margin-bottom: 0.6rem">:</span>
            <span style="min-width: 3rem">
              {{ String(timer.s).padStart(2, "0") }}
            </span></v-progress-circular
          >
        </div>
      </v-col>
      <v-col class="col" cols="12" xs="12" md="4">
        <div class="focus-control">
          <!--          <div>{{ time }}</div>-->
          <div class="focus-select-gp">
            <transition name="focus">
              <v-slider
                class="focus-select"
                v-model="time"
                v-show="!timerStarted"
                vertical
                step="10"
                ticks
                :max="60"
                :min="20"
                thumb-color="#ffaf64"
                thumb-label="always"
                append-icon="mdi-timer-outline"
              ></v-slider>
            </transition>
            <transition name="focus">
              <v-btn
                key="1"
                class="focus-select-music"
                v-show="timerStarted"
                color="#545454"
                dark
                fab
                @touchstart="zzzMid"
                @click="showMusic"
              >
                <v-icon>mdi-music</v-icon>
              </v-btn>
            </transition>
          </div>

          <div class="focus-btn-gp">
            <v-btn
              key="1"
              class="focus-btn"
              v-show="!timerStarted"
              color="#ffaf64"
              dark
              large
              fab
              @touchstart="zzzShort"
              @touchend="zzzMid"
              @click="start"
            >
              <v-icon>mdi-play</v-icon>
            </v-btn>
            <v-btn
              key="2"
              class="focus-btn"
              v-show="timerStarted"
              color="error"
              dark
              large
              fab
              @touchstart="zzzShort"
              @touchend="zzzMid"
              @click="stop"
            >
              <v-icon>mdi-bell-sleep</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="quitFocus" persistent max-width="290" class="dialog">
      <v-card dark>
        <v-card-title class="text-h5"> 退出专注模式？ </v-card-title>
        <v-card-text
          >您还没有完成当前的专注任务，退出即为放弃挑战！</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#3c3e9b" text @click="quitFocus = false">
            继续专注
          </v-btn>
          <v-btn color="#3c3e9b" text @click="quit"> 取消专注 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <svg-bg ref="bg" class="bg" :key="bgKey"></svg-bg>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { buttonVibrate, nosleep, setSystemColor } from "@/mixin/mixin";
import AppSystemBar from "@/components/app/appSystemBar";
import SvgBg from "@/views/focus/components/svgBg";
import Timerdown from "@lvdongy/timedown";
import { isEmpty, wakeLock } from "@/utils/common";
import { createLocalPushMsg } from "@/utils/push";
import $api from "@/api/api";
export default {
  name: "focusIndex",
  mixins: [buttonVibrate, setSystemColor, nosleep],
  components: {
    SvgBg,
    AppSystemBar,
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      appThemeConfig: "appThemeConfig",
      browser: "browser",
      systemBarHeight: "systemBarHeight",
      screenHeight: "screenHeight",
      screenWidth: "screenWidth",
      focusMode: "focusMode",
    }),
    progressValue() {
      if (this.startTime && this.endTime) {
        let total = (this.endTime - this.startTime) / 1000;
        let progress = (1 - (this.timer.m * 60 + this.timer.s) / total) * 100;
        return progress;
      }
      return 0;
    },
  },
  data: () => ({
    quitFocus: false,
    bgKey: 0,
    timer: {
      m: 0,
      s: 0,
    },
    timerStarted: false,
    time: 30,
    startTime: null,
    endTime: null,
    failTimeOut: null,
  }),
  watch: {
    time() {
      this.zzzShort();
    },
  },
  methods: {
    goIndex() {
      this.zzzShort();
      this.$router.replace("/");
    },
    syncData() {
      let postData = {
        time: this.time,
      };
      $api
        .analyseFocus(postData)
        .then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            console.info(res.msg);
          } else {
            // 不弹出提示打扰用户了
            this.$toast.error(res.msg);
            console.log("云同步专注时长失败！", res.msg);
          }
        })
        .catch((err) => {
          this.$toast.error("云同步专注时长失败！");
          console.log(err);
        });
    },
    start() {
      this.$refs.bg.goDark();
      this.startTimer();
      this.timerStarted = true;
      this.enableNoSleep();
      if (this.isApp) {
        wakeLock();
        document.addEventListener(
          "pause",
          this.showAlert,
          //从前台切换到后台
          false
        );
        document.addEventListener("resume", this.showResume, false);
      } else {
        document.addEventListener(
          "visibilitychange",
          this.changeResumeAlert,
          false
        );
      }
    },
    changeResumeAlert() {
      if (!document.hidden) {
        //处于当前页面
        this.showResume();
      } else {
        this.showAlert(); // do something
      }
    },
    showAlert() {
      console.log("****专注模式：App或网页进入后台");
      this.$store.commit("pauseFocusMode");
      this.$toast.error(
        "专注模式：您已经离开了 NotEver ，2分钟内未返回则挑战失败",
        {
          timeout: 9000,
        }
      );
      if (this.isApp) {
        createLocalPushMsg(
          "专注模式",
          "您已经离开了 NotEver ，2分钟内未返回则挑战失败。",
          "focusModeAlert"
        );
        this.failTimeOut = setInterval(() => {
          console.log("两分钟倒计时");
          createLocalPushMsg(
            "专注模式",
            "专注模式：挑战失败，您离开了 NotEver ，超过2分钟",
            "focusModeAlert"
          );
          clearInterval(this.failTimeOut);
        }, 2 * 60 * 1000);
        // 后台存活计数
        // let i = 0;
        // setInterval(() => {
        //   console.log("计时器", i);
        //   i += 1;
        // }, 1000);
      }
    },
    showResume() {
      console.log("****专注模式：App或网页恢复运行");
      console.log("定时器清除");
      clearInterval(this.failTimeOut);
      let time = parseInt(new Date().getTime());
      if (this.focusMode.pauseTime) {
        let leaveTime = time - this.focusMode.pauseTime;
        if (leaveTime > 2 * 60 * 1000) {
          this.quit();
          this.$toast.error(
            "专注模式：挑战失败，您离开了 NotEver ，超过2分钟",
            {
              timeout: 9000,
            }
          );
        } else {
          this.$toast("专注模式：您重新回到了专注模式！请继续保持！", {
            timeout: 9000,
          });
          this.$store.commit("resumeFocusMode");
        }
      }
    },
    stop() {
      this.quitFocus = true;
    },
    quit() {
      this.$toast("专注" + this.time + "分钟的挑战，失败！", {
        timeout: -1, // default
      });
      this.$store.dispatch("setFocusMode", {
        startTime: null,
        endTime: null,
        pauseTime: null,
      });
      console.log("专注模式挑战失败！");
      this.timer = {
        m: 0,
        s: 0,
      };
      this.timerStarted = false;
      this.time = 30;
      this.startTime = null;
      this.endTime = null;
      this.quitFocus = false;
      this.bgKey += 1;
      this.failTimeOut = null;
      this.setSystemStatusBarStyle(this.appThemeConfig.dark ? "light" : "dark");
      if (this.$route.path.match(/^\/focus/i)) {
        this.$store.commit("setAppSystemBarColor", "#a7b6f1");
      }
      // 恢复睡眠
      this.disableNoSleep();
      this.clearListener();
    },
    finish() {
      if (
        this.startTime &&
        this.timer.config.id &&
        this.timer.config.id === this.startTime
      ) {
        this.syncData();
        this.$toast("专注" + this.time + "分钟的挑战，成功！", {
          color: "green",
          timeout: -1, // default
        });
        console.log("专注模式挑战成功！");
        this.$store.dispatch("setFocusMode", {
          startTime: null,
          endTime: null,
          pauseTime: null,
        });
        this.timer = {
          m: 0,
          s: 0,
        };
        this.timerStarted = false;
        this.time = 30;
        this.startTime = null;
        this.endTime = null;
        this.bgKey += 1;
        this.failTimeOut = null;
        this.setSystemStatusBarStyle(
          this.appThemeConfig.dark ? "light" : "dark"
        );
        // 恢复睡眠
        this.disableNoSleep();
        this.clearListener();
      }
    },
    clearListener() {
      document.removeEventListener(
        "pause",
        this.showAlert,
        //从前台切换到后台
        false
      );
      document.removeEventListener("resume", this.showResume, false);
      document.removeEventListener(
        "visibilitychange",
        this.changeResumeAlert,
        false
      );
    },
    startTimer(time) {
      this.endTime = time;
      if (isEmpty(this.endTime)) {
        this.startTime = parseInt(new Date().getTime());
        this.endTime = this.startTime + this.time * 60 * 1000;
      }
      this.timer = new Timerdown({
        id: this.startTime,
        endTime: this.endTime,
        endCallback: this.finish,
      });
    },
    showMusic() {
      this.$toast.success("我们在侧边抽屉放置了白噪音播放器！");
      this.$store.dispatch("setShowDrawer", true);
    },
  },
  activated() {
    console.log("focus组件被激活");
    this.$store.dispatch("setShowSysAppTab", false);
    this.$store.commit("setAppSystemBarColor", "#a7b6f1");
    this.setSystemFootColor("#2f2c35");
    if (this.timerStarted) {
      document
        .querySelector('meta[name="theme-color"]')
        .setAttribute("content", "#271e3e");
      this.setSystemStatusBarStyle("light");
      // 防止睡眠
      this.enableNoSleep();
    } else {
      this.setSystemStatusBarStyle(this.appThemeConfig.dark ? "light" : "dark");
    }
  },
  deactivated() {
    console.log("focus组件被暂停");
    if (this.timerStarted) {
      this.$refs.bg.clearTimeOut();
      this.$toast("专注模式在后台运行，<br/>您可以学习其他内容！");
    }
    this.setSystemStatusBarStyle(this.appThemeConfig.dark ? "light" : "dark");
    // 恢复睡眠
    this.disableNoSleep();
  },
  created() {
    console.log("focus组件创建");
    this.$store.dispatch("setShowSysAppTab", false);
    this.$store.dispatch("setAppSystemBarColor", "#a7b6f1");
    this.setSystemFootColor("#2f2c35");
  },
  mounted() {
    console.log("focus组件挂载");
  },
};
</script>

<style lang="scss" scoped>
.col {
  display: flex;
}
#focus {
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  background-color: #8ca0ff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog {
    z-index: 3;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .bg {
    width: 100%;
    height: 100%;
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
  }

  .focus-content {
    justify-content: center;
    margin: 0 0 0 0;
    z-index: 1;
    width: 100%;
    height: 45rem;
    word-wrap: break-word;
    //background-color: rgba(85, 25, 87, 0.7);
    position: relative;
    .timer {
      width: 10.95rem;
      height: 10.95rem;
      background-color: #ffffffcc;
      border-radius: 50%;
      margin: 2rem auto 0 auto;
      align-self: center;

      ::v-deep .v-progress-circular__underlay {
        stroke: #8ac5ff;
      }

      ::v-deep .v-progress-circular__info {
        height: 2.5rem;
        align-items: center;
        text-align: center;
        font-weight: bold;
        font-family: dosisbold, sans-serif;
        font-size: 2rem;
        line-height: 2rem;
        letter-spacing: 0.2rem;
        vertical-align: middle;
      }

      ::v-deep .v-progress-circular {
        stroke-linecap: round;
      }
    }

    .focus-control {
      align-self: center;
      margin: 0 auto 0 auto;
      z-index: 1;
      width: 80%;
      max-width: 100%;
      height: 23rem;
      word-wrap: break-word;
      //background-color: red;
      .focus-select-gp {
        height: 15rem;
        margin: 0 auto;
        width: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .focus-select-music {
        align-self: flex-end;
      }
      .focus-select {
        margin: 0 auto;
        color: #eeeeee;
        ::v-deep .v-slider__track-container {
          width: 1rem;
          border-radius: 1rem;
          overflow: hidden;
          height: calc(100% + 1.2rem);
          top: -0.65rem;
        }
        ::v-deep .v-icon {
          color: #eeeeee;
        }
        ::v-deep .v-input__append-outer {
          margin-bottom: 0.8rem;
          background-color: #8ac5ff;
          border-radius: 50%;
          padding: 0.2rem;
        }
      }
      .focus-btn-gp {
        margin: 2rem auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .focus-btn {
        display: block;
      }
    }
  }
}
.focus-enter-active {
  transition: all 0.6s ease;
  transition-delay: 0.5s;
}

.focus-leave-active {
  transition: all 0.5s ease;
}
.focus-enter,
.focus-leave-to {
  opacity: 0;
  transform: translateX(60px);
}
</style>
